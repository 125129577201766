import menuData from "@muzfed/data/menu-data";
import { Link } from "react-router-dom";

const NavMenus = () => {
  return (
    <ul>
      {menuData.map((menu, i) =>
        menu.show_on_menu === true ? (
          <li
            key={i}
            className={`${menu.has_dropdown ? "has-dropdown" : ""}
   ${menu.mega_menu ? "has-mega-menu" : ""}`}
          >
            <Link to={menu.link}>
              {menu.title}{" "}
              {menu.has_dropdown && <i className="fal fa-angle-down"></i>}
            </Link>
            {menu.has_dropdown && (
              <ul className="submenu text-start">
                {menu.sub_menus &&
                  menu.sub_menus.map((sub_m, i) => (
                    <li key={i}>
                      <Link
                        to={sub_m.link}
                        download={sub_m.download === true ? true : undefined}
                        target={sub_m.download === true ? "_blank" : "_self"}
                      >
                        {sub_m.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ) : null
      )}
    </ul>
  );
};

export default NavMenus;
