const menuData = [
  {
    id: 1,
    mega_menu: false,
    has_dropdown: true,
    show_on_menu: true,
    title: "Hakkımızda",
    link: "#!",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-drum.jpg"),
    sub_menus: [
      { link: "/hakkimizda/yonetim", title: "Yönetim" },
      { link: "/hakkimizda/biz-kimiz", title: "Biz Kimiz?" },
      { link: "/hakkimizda/tuzuk", title: "Tüzük" },
      { link: "/hakkimizda/uyelik-sartlari", title: "Üyelik Şartları" },
      { link: "/hakkimizda/uyeler", title: "Üyeler" },
      { link: "/hakkimizda/yetki-belgesi", title: "Yetki Belgesi" },
      { link: "/hakkimizda/lisans-sozlesmesi", title: "Lisans Sözleşmesi" },
      {
        link: "/hakkimizda/yonergeler",
        title: "Yönergeler",
      },
      {
        link: "/hakkimizda/genel-kurur-kararlari",
        title: "Genel Kurur Kararları",
      },
      {
        link: "/hakkimizda/telif-haklari-alaninda-meslek-birligi-yonetmeliginin-58-maddesi",
        title:
          "Telif Hakları Alanında Meslek Birliği Yönetmeliğinin 58. Maddesi Gereği Diğer Bildirimler",
      },
      {
        link: "/assets/docs/MUZFED-2023-Seffaflik-Raporu.pdf",
        title: "2023 Şeffaflık Raporu",
        download: true
      },
    ],
  },
  {
    id: 2,
    mega_menu: false,
    has_dropdown: false,
    show_on_menu: true,
    title: "Lisans Alan Firmalar",
    link: "/firmalar",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-vinyl.jpg"),
  },

  {
    id: 3,
    mega_menu: false,
    has_dropdown: false,
    show_on_menu: true,
    title: "İletişim",
    link: "/iletisim",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-cloudmusic.jpg"),
  },
  {
    id: 4,
    mega_menu: false,
    has_dropdown: false,
    show_on_menu: true,
    title: "S.S.S.",
    link: "/sikca-sorulan-sorular",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-volume.jpg"),
  },
  {
    id: 5,
    mega_menu: false,
    has_dropdown: false,
    show_on_menu: true,
    title: "Tarifeler",
    link: "/tarifeler",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-guitar.jpg"),
  },
  {
    id: 6,
    mega_menu: false,
    has_dropdown: false,
    show_on_menu: false,
    title: "Gizlilik",
    link: "/gizlilik",
    pageHeader: require("@muzfed/assets/img/page-header/page-header-equalizer.jpg"),
  },
];

export default menuData;
